
figure.tooltip-image {
  max-height: 335px;
  min-height: 335px;
  max-width: 240px;
  min-width: 240px;
}

div.tooltip-container {
  opacity: 100 !important;
  padding: 5px !important;
}

div.image-placeholder {
  top: 50%;
}