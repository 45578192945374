@import "src/assets/variables";

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;

  &:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    animation: lds-dual-ring 1.2s linear infinite;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
  }

  &-dark:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    animation: lds-dual-ring 1.2s linear infinite;
    border: 6px solid $dark;
    border-color: $dark transparent $dark transparent;
  }

  &-primary:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    animation: lds-dual-ring 1.2s linear infinite;
    border: 6px solid $primary;
    border-color: $primary transparent $primary transparent;
  }
}

.lds-dual-ring-xs {
  display: inline-block;
  width: 40px;
  height: 40px;

  &:after {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    margin: 4px;
    border-radius: 50%;
    animation: lds-dual-ring 1.2s linear infinite;
    border: 3px solid #fff;
    border-color: #fff transparent #fff transparent;
  }

  &-dark:after {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    margin: 4px;
    border-radius: 50%;
    animation: lds-dual-ring 1.2s linear infinite;
    border: 3px solid $dark;
    border-color: $dark transparent $dark transparent;
  }

  &-primary:after {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    margin: 4px;
    border-radius: 50%;
    animation: lds-dual-ring 1.2s linear infinite;
    border: 3px solid $primary;
    border-color: $primary transparent $primary transparent;
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}