@import "src/assets/variables";

.field {
  margin-top: 0.5rem;

  label {
    font-size: $size-6;
    font-weight: $weight-bold;
  }

  div.control {
    label.switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 30px;

      input {
        opacity: 0;
        width: 0;
        height: 0;

        &:disabled + .slider:before {
          background-color: $light;
          border: $grey-light 1px solid;
        }

        &[disabled]:checked + .slider:before {
          background-color: $grey-light;
        }

        &:checked + .slider {
          background-color: $primary;
        }

        &:disabled + .slider {
          background-color: $grey-lightest;
        }

        &:checked + .slider:before {
          -webkit-transform: translateX(29px);
          -ms-transform: translateX(29px);
          transform: translateX(29px);
        }
      }

      .slider {
        position: absolute;
        cursor: pointer;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $grey-light;
        -webkit-transition: .4s;
        transition: .4s;

        &.round {
          border-radius: 34px;
        }

        &.round:before {
          border-radius: 50%;
        }

        &:before {
          position: absolute;
          content: "";
          height: 22px;
          width: 22px;
          left: 4px;
          bottom: 4px;
          background-color: $light;
          -webkit-transition: .4s;
          transition: .4s;
        }
      }
    }
  }
}