@import "src/assets/variables";

div.card-page-title {
  margin-bottom: 0.5rem !important;
}

div.image-column {
  align-items: baseline;
  padding-top: 0;

  figure {
    max-width: 240px;
    min-width: 240px;
    min-height: 335px;
  }
}

.card-info {
  .info-header {
    line-height: 18px;
  }

  .block {
    margin-bottom: 1rem;

    &.subtitle-block {
      margin-bottom: 0.75rem;
    }
  }

  hr {
    margin-bottom: 0.5rem;
  }
}

hr.column-separator {
  @include from($tablet) {
    display: none;
  }
}

.card-info-columns {
  .column {
    padding-bottom: 0;
    padding-top: 0;
  }
}
.multiline {
  white-space: pre-wrap;
}