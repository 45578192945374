body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: $dark;
}

body::-webkit-scrollbar-thumb {
  background: $primary-light;
}