@import "src/assets/variables";

@media(max-width: $mobile) {
  .columns.columns-mobile-inverted {
    flex-direction: column-reverse;
    display: flex;
  }
}

.tile.box {
  cursor: pointer;
}

.box {
  div.subtitle-container {
    margin: 0;

    .column {
      padding: 0;
      .subtitle {
        margin-bottom: 0.5rem;
      }

      .button.button-add-item {
        float: right;
        margin-bottom: 15px;
      }
    }
  }

  hr {
    margin-top: 0;
  }
}