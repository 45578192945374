.content {
  figure.image {
    margin: 0;
  }
}

div.title-column {
  display: flex;
  justify-content: left; 
  align-items: center; 
  p.title{
    text-align: center;
  }  
}