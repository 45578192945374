@import "src/assets/variables";

@media(max-width: $mobile) {
  .columns.columns-mobile-inverted {
    flex-direction: column-reverse;
    display: flex;
  }
}

div.columns.filter-columns {

  div.column {
    padding-top: 0;
    padding-bottom: 5px;

    .field {
      margin-top: 0;
    }
  }
}

.card.card-filters {
  margin-bottom: 5px;
}

div.box {
  p.cards-database-title {
    margin-bottom: 1rem;
  }
}