@import "theme";
@import "overrides";
@import "~bulma/bulma";
@import "components/_components";
@import "animations";
@import "forms";

html {
  overflow-y: auto;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.has-custom-background {
  background: $white-ter;
  background: linear-gradient(180deg, $white-ter 0%, $grey-lighter 100%);
}

.is-responsive {
  @extend .is-four-fifths-fullhd;
  @extend .is-full-mobile;
}

.is-responsive-footer {
  @extend .is-half-fullhd;
  @extend .is-full-mobile;
}

.is-responsive-medium {
  @extend .is-two-thirds-widescreen;
  @extend .is-offset-2-widescreen;
  @extend .is-three-fifths-fullhd;
  @extend .is-offset-one-fifth-fullhd;
}

.is-responsive-small {
  @extend .is-two-thirds-tablet;
  @extend .is-offset-2-tablet;
  @extend .is-half-desktop;
  @extend .is-offset-one-quarter-desktop;
  @extend .is-one-third-fullhd;
  @extend .is-offset-one-third-fullhd;
}