$primary: hsl(281, 43%, 22%);
$success: hsl(141, 28%, 36%);
$link: hsl(207, 40%, 52%);
$info: hsl(233, 50%, 37%);
$warning: hsl(54, 39%, 56%);
$danger: hsl(0, 64%, 26%);

$white: hsl(210, 10%, 96%);
$white-bis: hsl(210, 11%, 94%);
$white-ter: hsl(210, 15%, 91%);

// Support for css variables - currently unavailable
//@import "~css-vars/css-vars";
//$css-vars-use-native: true;
//
//@include css-vars((
//        --primary: $primary,
//        --info: $info,
//))