button.foil-control {
  min-width: 70px;
  padding-left: 5px;
  padding-right: 5px;
}

button.card-image-button {
  padding-left: 5px;
  padding-right: 5px;
}

input.inventory-count {
  min-width: 50px;
  max-width: 75px;
  padding-left: 5px;
  padding-right: 5px;
}

.edit-disabled {
  pointer-events: none;
  touch-action: none;
}

.subtitle-block {
  p {
    button {
      margin-left: 5px;
    }
  }
}